import React, { useState } from 'react';

import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';

import { css, StyleSheet } from 'aphrodite/no-important';
import { lightTheme, darkTheme } from './styles/styles';

import logoDark from './assets/daiguren_dark_bg.png';
import logoLight from './assets/daiguren_light_bg.png';
import './App.css';

function App() {

  const [theme, setTheme] = useState('light');

  const setDarkTheme = () => {
    setTheme('dark');
  }

  const setLightTheme = () => {
    setTheme('light');
  }

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        
        <img src={theme === 'light' ? logoLight : logoDark} className="Daiguren-logo" alt="logo" />
        <h1 className={ css([extraStyles.comingSoonText])}>
          For flâneurs AND Jed Mckenna fans
        </h1>
      </>
    </ThemeProvider>
  );
}

const extraStyles = StyleSheet.create({
  comingSoonText: {
    marginTop: '5vh',
    textAlign: 'center',
  }
})

export default App;
